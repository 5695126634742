var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useSelector } from "react-redux";
//CONSTANS
import { AUTH_ROUTES, PUBLIC_ROUTES, ADMIN_ROUTES, COMPANY_ROUTES, EXPERT_ROUTES, USER_ROUTES, STURTUP_ROUTES } from "./routes";
//Routing
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "../constants";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import { ContainerLayout } from "../components/Layout/ContainerLayout";
import GetCookie from "../hooks/cookies/getCookie";
function RootRoute() {
    var isSuperUser = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.is_superuser; });
    var role = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.role; });
    var renderedForLoggedUser = function (scene) {
        if (GetCookie("isUserAuth") && JSON.parse(GetCookie("isUserAuth")) === true) {
            return scene;
        }
        else {
            return _jsx(Navigate, { to: ROUTES.notFoundPage });
        }
    };
    var renderedForUser = function (scene) {
        if (role) {
            if (role === 3) {
                return scene;
            }
            else {
                return _jsx(Navigate, { to: ROUTES.notFoundPage });
            }
        }
    };
    var renderedForExpertUser = function (scene) {
        if (role) {
            if (role === 2) {
                return scene;
            }
            else {
                return _jsx(Navigate, { to: ROUTES.notFoundPage });
            }
        }
    };
    var renderedForCompanyUser = function (scene) {
        if (role) {
            if (role === 1) {
                return scene;
            }
            else {
                return _jsx(Navigate, { to: ROUTES.notFoundPage });
            }
        }
    };
    var renderedForStartupUser = function (scene) {
        if (role) {
            if (role === 4) {
                return scene;
            }
            else {
                return _jsx(Navigate, { to: ROUTES.notFoundPage });
            }
        }
    };
    var renderedForAdmin = function (scene) {
        if (isSuperUser === true || isSuperUser === false) {
            if (isSuperUser) {
                return scene;
            }
            else {
                return _jsx(Navigate, { to: ROUTES.notFoundPage });
            }
        }
    };
    return (_jsx(Suspense, __assign({ fallback: "" }, { children: _jsxs(Routes, { children: [_jsxs(Route, __assign({ path: "/", element: _jsx(ContainerLayout, {}) }, { children: [PUBLIC_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path && route.path, element: route.Component, index: route.isIndexElem }, route.path);
                        }), AUTH_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path, element: renderedForLoggedUser(route.Component) }, route.path);
                        }), USER_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path, element: renderedForUser(route.Component) }, route.path);
                        }), EXPERT_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path, element: renderedForExpertUser(route.Component) }, route.path);
                        }), COMPANY_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path, element: renderedForCompanyUser(route.Component) }, route.path);
                        }), STURTUP_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path, element: renderedForStartupUser(route.Component) }, route.path);
                        }), ADMIN_ROUTES.map(function (route) {
                            return _jsx(Route, { path: route.path, element: renderedForAdmin(route.Component) }, route.path);
                        })] })), _jsx(Route, { path: "*", element: _jsx(NotFoundPage, {}) })] }) })));
}
export default RootRoute;
